import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Moment } from "moment";

export interface getHourAvgHistorical_item {
  id: number;
  dev_id: string;
  date: string;
  hour: number;
  minute: number;
  so2: string;
  no2: string;
  pm1: string;
  pm25: string;
  pm10: string;
  int_temp: string;
  int_humi: string;
  ext_temp: string;
  ext_humi: string;
  pm_temp: string;
  pm_humi: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface getHourAvgHistorical_Returns {
  status: string;
  total_data: number;
  data: getHourAvgHistorical_item[];
}

export const getHourAvgHistorical = async (
  dev_id: string,
  start_datetime: Moment,
  end_datetime: Moment
): Promise<AxiosResponse<getHourAvgHistorical_Returns>> => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: `http://monster1.distronix.in:1100/v1.0/sens/real/get_15min_avg?start_time=${start_datetime.format(
      "YYYY-MM-DD HH:mm:ss"
    )}&end_time=${end_datetime.format(
      "YYYY-MM-DD HH:mm:ss"
    )}&location_id=${dev_id}`,
  };
  const result: AxiosResponse<getHourAvgHistorical_Returns> = await axios(
    config
  );
  return result;
};
